function PaginateScrollModel() {
    this.totalItems = 0;
    this.itemsPerPage = 0;
    this.curPaginateViewIndex = 0;
    this.setup = function(totalItems, itemsPerPage ) {   // method
        this.totalItems = totalItems;
        this.itemsPerPage = itemsPerPage;

         var totalPages = this.getTotalPages();
         if (totalPages ===0) this.curPaginateViewIndex = 0;
         else {

            var chkPageIndex = this.getCurPageIndex();
            if (chkPageIndex >= totalPages && totalPages > 0  ) {
                this.setCurPageIndex_(totalPages- 1);
            }

         }
      //  this.traceStuff();
      
    };
    this.traceStuff = function() {
         console.log(this.curPaginateViewIndex + " / "+this.totalItems + "," +this.itemsPerPage + " :"+this.getCurPageIndex() + ">> "+this.getTotalPages());
    };
    this.getTotalPages =  function() { // computed
        return Math.ceil( this.totalItems / this.itemsPerPage );
    };
    this.hasNextPage =  function() {   // computed
        return this.getCurPageIndex() < this.getTotalPages() - 1;
    };
    this.hasPrevPage =  function() {   // computed
         return this.getCurPageIndex() > 0;
    };
    this.getCurPageIndex =  function() {   // computed
        return Math.floor(this.curPaginateViewIndex / this.itemsPerPage);
    };
    this.setCurPageIndex_ = function(index) { 
        this.curPaginateViewIndex =    index * this.itemsPerPage;
    };
    this.setCurPageIndex = function(index) {
        this.curPaginateViewIndex =    index * this.itemsPerPage;
    };
    this.gotoNextPage =  function() {  // method
         if (!this.hasNextPage()) return false;
        this.curPaginateViewIndex  = (this.getCurPageIndex() + 1) * this.itemsPerPage;
        return true;
    };
     this.gotoPrevPage =  function() {  // method
         if (!this.hasPrevPage()) return false;
        this.curPaginateViewIndex  = (this.getCurPageIndex() - 1) * this.itemsPerPage + (this.itemsPerPage-1);
        return true;
    };
    this.getClampDownPaginateViewIndex = function() {  // computed
        var totalPages = this.getTotalPages();
        var curPageIndex = this.getCurPageIndex();
       return totalPages  <=1  || curPageIndex !== totalPages - 1  ?   curPageIndex * this.itemsPerPage :  this.totalItems - this.itemsPerPage;
     
    };
    this.getClampDownScrollFloatingIndex = function() {
        return this.getClampDownPaginateViewIndex() /  this.itemsPerPage;
    };

}