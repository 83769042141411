// /* jshint multistr: true */
// /* jshint validthis: true */
// /* jshint loopfunc: true */
// /* jshint boss:true  */
// /* jshint bitwise: false */
// /* jshint multistr: true */
/*jshint -W027*/
/*jshint -W041*/
/*jshint -W121*/
/*jshint -W008*/
/**
 * @param {JQuery} containerJ
 */
function DigitsContainer(containerJ, customScrollEase) {
    var digits = containerJ.children("div");
    var digitRollers  = digits.children(".numberwrap");

    var scrollEase =  customScrollEase != null ? customScrollEase :  Linear.easeNone;
  

/**
 * @type {TweenMax[]}
 */
   var digitTweens = [];

   var digitTimeline = new TimelineMax();

    // assume got digitRollers
     var numDigits = digitRollers.length;
    

     /**
     * @type {TweenMax[]}
     */
     function resetTween(tw) {
         tw.time(0);
     }

     function setupDigitTweens() {
         var i = numDigits;
         var multiplier = 1;
         var t;
         var dt;
         var useEase = scrollEase;//SteppedEase.config(10);//scrollEase;
        // console.log(useEase);
         while(--i > -1) {
            digitTweens.push(t =  TweenMax.to(digitRollers[i], 1, { repeat:-1, ease:useEase, yPercent :-90.9090} ) );  //, repeat:-1  //rotationX:360
            t.timeScale(1/multiplier);
             multiplier*=10;
             t.pause();
          //   dt.pause();
         }
     }
     //


     setupDigitTweens();

     function getRDigits(num) {
        var digits = [];
        while ( num > 0) {
            digits[digits.length] = num % 10;
            num = parseInt(num / 10);  // without the parseInt, allow floating point precision
        }
        return digits;
     }

     function getFloatingPoint(num) {
      
         return num - Math.floor(num);
     }

    var settedITime = 0;
    var setInitialTime =  this.setInitialTime = function(value) {
        settedITime = value;
         var digits = getRDigits(value);
         var i;
         var lastT = 0;
         var t;
         var addT;
         var len = digits.length;
         for (i=0; i< len; i++) {
             t = digits[i]/10;
            
            digitTweens[i].time(t  );  
            lastT = t; 
         }
         while( i < numDigits) { 
             digitTweens[i].time( 0);   
             i++;
         }
     };
     var getInitialTime  = this.getInitialTime = function(value) {
         return settedITime;
     };


     function GetLerpRotationTimes(value) {
         var arr = [];

           var digits = getRDigits(value);
         var i;
         var lastT = 0;
         var t;
         var addT;
         var len = digits.length;
         for (i=0; i< len; i++) {
             t = digits[i]/10;
             addT = i > 0 ? 0.1*getFloatingPoint(value/Math.pow(10,i)) : 0;
            
//+ (i > 0 ? lastT*0.1 : 0)
            arr.push(t + addT  );  
            lastT = t; 
         }
         while( i < numDigits) { 
             addT = i > 0 ? 0.1*getFloatingPoint(value/Math.pow(10,i))  : 0;
            arr.push( addT);   
             i++;
         }

       
         return arr;
     }
  //   this.GetLerpRotationTimes = GetLerpRotationTimes;

     
var settedLTime = 0;
var getLerpTime = this.getLerpTime = function(value) {
    return settedLTime;
};
  var setLerpTime =  this.setLerpTime = function(value) {
        settedLTime  = value;
         var digits = getRDigits(value);
         var i;
         var lastT = 0;
         var t;
         var addT;
         var len = digits.length;
         for (i=0; i< len; i++) {
             t = digits[i]/10;
             addT = i > 0 ? 0.1*getFloatingPoint(value/Math.pow(10,i)) : 0;
            
//+ (i > 0 ? lastT*0.1 : 0)
            digitTweens[i].time(t + addT  );  
            lastT = t; 
         }
         while( i < numDigits) { 
             addT = i > 0 ? 0.1*getFloatingPoint(value/Math.pow(10,i))  : 0;
             digitTweens[i].time( addT);   
             i++;
         }
     };

  

    this.getCurrentTime = function() {
        var i;
        var len = numDigits;
        var multiplier = 1;
        var t=0;
        for (i=0; i< len; i++) {
           // console.log( digitTweens[i].time());
            t+= (i > 0 ?  Math.floor( digitTweens[i].time() *10 )*0.1 : digitTweens[i].time() )  * 10 *  multiplier ;
            multiplier*=10;
        }
        return t;
    };


  
    
    this.getTimelineFromTo = function(fromTime, toTime, lerp) {
        if (lerp) {
            this.setLerpTime(fromTime);
        }
        else {
            this.setInitialTime(fromTime);
        }

        return this.getTimelineTo(toTime);
    };

 

    this.getTimelineTo = function(toTime) {
        var curTime = this.getCurrentTime();
    
        var tl = new TimelineMax();
        var len  = numDigits; 
        var multiplier = 1;
        var timeDiff = toTime - curTime;
        var numRevs;

        var f;
        var time;
        var extraTime;

        var t;

        var b;
        var a;
        var bi;
        var ai;
        var dt;
        var addT;

        var tw;
        var snapDest;
            var gotDigitUp;

         var roundUpAmount;
         var lastT;
        for (i=0; i< numDigits; i++) {
             
               b =   toTime/(10*Math.pow(10, i));
               a = curTime/(10*Math.pow(10,i));

               bi = Math.floor(b);
               ai = Math.floor(a);
               

                 digitTweens[i].timeScale(1/multiplier);  // normalize timeScale
                 t = 0;

              
                gotDigitUp = bi - ai >= 1;


               time = digitTweens[i].time();
               roundUpAmount = gotDigitUp && time >  0 && time < 1 ? 1 - time : 0;
              // if (roundUpAmount < 0.1) roundUpAmount = 0;
              
               if (time == 1) {
                   digitTweens[i].time(0);
               }

               numRevs =gotDigitUp ?  bi - ai - (roundUpAmount !== 0 ? 1 : 0) : 0;
               extraTime  = b - bi;
        
            //   console.log(roundUpAmount + ", "+    numRevs + ", "+extraTime + ":"+gotDigitUp);
            
             if (roundUpAmount !==0) {
                 addT = roundUpAmount * multiplier;
                  if (addT > lastT) {
                       console.log("roundUpAmunt exception clamp..." + addT + ">"+lastT + " @:"+i);
                      addT= lastT;
                    
                  }

                 tl.add( new TweenMax(digitTweens[i], addT, {  ease:scrollEase,  time:1, onComplete:resetTween, onCompleteParams:[digitTweens[i]] } ), t, "start" );
                 t+= addT;
             }
            
              // console.log(numRevs);
             if (numRevs > 0) {
              
                tl.add( new TweenMax(digitTweens[i], multiplier, {   ease:scrollEase,  time:1, repeat:numRevs -1, onComplete:resetTween, onCompleteParams:[digitTweens[i]] } ), t, "start" );
                  t +=   multiplier + multiplier*(numRevs-1);
            }


             if (extraTime !== 0) {

                    snapDest = i !== 0 ?  Math.floor(extraTime * 10) * 0.1 : extraTime;  // snap by unit tenth
                    if (numRevs === 0 && roundUpAmount ===0 && snapDest < time) {
                        digitTweens[i].time(snapDest);
                    }
                  else {
                      addT = i >= 1 ?  lastT-t :  extraTime*multiplier; // determine if need to reference previous digit for time
                      if (addT <= 0) {
                           console.log("extraTime exception exit..."+addT+ " @:"+i);
                         // alert("EXCEPTION <=0 OCCURED for addT:"+addT);
                         // alert(t - lastT);
                         
                      }

                      if (addT > 0) {
                        tl.add( new TweenMax(digitTweens[i], addT, {    ease:scrollEase, time:snapDest } ), t, "start" );
                     //  console.log("INSERT at:"+t +  " :: "+addT );
                       t +=  addT;
                      }
                     
                  }
              }
            //  console.log("Total time: "+t);
              lastT = t;
             multiplier *= 10;

        }
      //  console.log("TOTAL timeline time:"+tl.totalDuration());
        
        tl.pause();
        return tl;
    };

    function diff(a,b) {
        return a >= b ? a - b : b - a;
    }

    this.tweenToLerp = function(duration, ease) {
            //this.getCurrentTime();
           var totalDuration = 0;
            if (!ease) ease = scrollEase;
            var d;
            var tw;
          var lerpValues = GetLerpRotationTimes(this.getCurrentTime());
            var len = lerpValues;
            var multiplier = 1/10;
          for (i=0; i<len; i++) {
            
               d = duration != null ? duration : diff(lerpValues[i], digitTweens[i].time() );
  
              tw =  TweenMax.to(digitTweens[i], d, { time:lerpValues[i], ease:ease }  );
           //     console.log("DIFF:"+ diff(lerpValues[i], digitTweens[i].time() ) + "x"+multiplier);
               totalDuration = d > totalDuration ? d : totalDuration; // tw.totalDuration();
               multiplier*=10;
          }
          //console.log(totalDuration);
          return totalDuration;
    };

    this.autoPlay = function(timeScaler) {
        if (!timeScaler) timeScaler = 1;

        var i = numDigits;
        var multiplier = 1;
        var len = numDigits;
        for (i=0; i<len; i++) {
            digitTweens[i].updateTo({repeat:-1}).play();
             digitTweens[i].timeScale(1/multiplier*timeScaler);
             multiplier*=10;
        }
    };

   

//this.setInitialTime(9700);
  //  this.autoPlay(10);

     
     // Test time function
  //this.setInitialTime(255.324);
  /*
    this.setInitialTime(9920);
    var ttest = this.getCurrentTime();
    console.log(ttest);
    setInterval( function() {
        ttest += 1/60;
        setInitialTime(ttest );
    }, 1000/60);
    */




    // Digital version
       // this function is not yet available
    this.getDigitalTimelineTo = function(toTime) {
        var curTime = this.getCurrentTime();
        console.log("CUR:"+curTime + " TO:"+toTime);
        var tl = new TimelineMax();
        var len  = numDigits; 
        var multiplier = 1;
        var timeDiff = toTime - curTime;
        var numRevs;

        var f;
        var time;
        var extraTime;

        var t;

        var b;
        var a;
        var bi;
        var ai;
        var dt;
        var addT;

        var tw;
        var snapDest;
            var gotDigitUp;

         var roundUpAmount;
         var lastT;

         var DUR = .4;
         var DUR_SOFF = 1 - DUR;
         

        for (i=0; i< numDigits; i++) {
             
               b =   toTime/(10*Math.pow(10, i));
               a = curTime/(10*Math.pow(10,i));

               bi = Math.floor(b);
               ai = Math.floor(a);
               

                 digitTweens[i].timeScale(1/multiplier);  // normalize timeScale
                 t = 0;

              
                gotDigitUp = bi - ai >= 1;


               time = digitTweens[i].time();
               roundUpAmount = gotDigitUp && time >  0 && time < 1 ? 1 - time : 0;
              // if (roundUpAmount < 0.1) roundUpAmount = 0;
              
               if (time == 1) {
                   digitTweens[i].time(0);
               }

               numRevs =gotDigitUp ?  bi - ai - (roundUpAmount !== 0 ? 1 : 0) : 0;
               extraTime  = b - bi;
        
            //   console.log(roundUpAmount + ", "+    numRevs + ", "+extraTime + ":"+gotDigitUp);
            
             if (roundUpAmount !==0) {
                 addT = roundUpAmount * multiplier;
                  if (addT > lastT) {
                       console.log("roundUpAmunt exception clamp..." + addT + ">"+lastT + " @:"+i);
                      addT= lastT;
                    
                  }

                 tl.add( new TweenMax(digitTweens[i], addT, {  ease:scrollEase,  time:1, onComplete:resetTween, onCompleteParams:[digitTweens[i]] } ), t, "start" );
                 t+= addT;
             }
            
              // console.log(numRevs);
             if (numRevs > 0) {
              
                tl.add( new TweenMax(digitTweens[i], multiplier, {   ease:scrollEase,  time:1, repeat:numRevs -1, onComplete:resetTween, onCompleteParams:[digitTweens[i]] } ), t, "start" );
                  t +=   multiplier + multiplier*(numRevs-1);
            }


             if (extraTime !== 0) {

                    snapDest = i !== 0 ?  Math.floor(extraTime * 10) * 0.1 : extraTime;  // snap by unit tenth
                    if (numRevs === 0 && roundUpAmount ===0 && snapDest < time) {
                        digitTweens[i].time(snapDest);
                    }
                  else {
                      addT = i >= 1 ?  lastT-t :  extraTime*multiplier; // determine if need to reference previous digit for time
                      if (addT <= 0) {
                           console.log("extraTime exception exit..."+addT+ " @:"+i);
                         // alert("EXCEPTION <=0 OCCURED for addT:"+addT);
                         // alert(t - lastT);
                         
                      }

                      if (addT > 0) {
                        tl.add( new TweenMax(digitTweens[i], addT, {    ease:scrollEase, time:snapDest } ), t, "start" );
                     //  console.log("INSERT at:"+t +  " :: "+addT );
                       t +=  addT;
                      }
                     
                  }
              }
            //  console.log("Total time: "+t);
              lastT = t;
             multiplier *= 10;

        }
      //  console.log("TOTAL timeline time:"+tl.totalDuration());
        
        tl.pause();
        return tl;
    };


}

