// /* jshint multistr: true */
// /* jshint validthis: true */
// /* jshint loopfunc: true */
// /* jshint boss:true  */
// /* jshint bitwise: false */
// /* jshint multistr: true */
/*jshint -W027*/
/*jshint -W041*/
/*jshint -W121*/
/*jshint -W008*/


function DockerTabs(tabs) {
         var fixedState = false;

        var tabsA = tabs.find("a");
        var firstTab = $(tabsA[0]);

        var docBody = $(document.body);

        

        function removeAnimatingClass() {
            tabs.removeClass("animating");
        }

        function removeAnimations() {
            TweenMax.killTweensOf(tabs);
            tabs.removeClass("animating").attr("style", "");
        }

        var curLast;

        function onDocBodyRelease() {
            docBody.unbind("mousedown", onDocBodyRelease);
            docBody.unbind("click", onDocBodyRelease);
            var last = tabs[0].getBoundingClientRect().height;

            tabs.removeClass("expanded").addClass("animating");
            curLast = tabs[0].getBoundingClientRect().height;
            TweenMax.from(tabs, 0.35, { height: last, ease: Cubic.easeOut, onComplete: removeAnimations });

        }
        tabs.mousedown(function (e) {
            e.stopPropagation();
        });



        tabsA.click(function (e) {  // need to factor this out
            e.preventDefault();
            var last;
            //   var now;unstackBreaked
            if (fixedState) {
                removeAnimations();
                if (unstackBreaked || tabs.hasClass("expanded")) {
                    $(this).trigger("funcClick");  // navigateToTabLink(tabsA.index(this));  // need to factor this out
                    onDocBodyRelease();

                }
                else {
                    last = tabs[0].getBoundingClientRect().height;
                    tabs.addClass("expanded");
                    tabs.addClass("animating");
                    TweenMax.from(tabs, 0.4, { height: last, ease: Cubic.easeOut, onComplete: removeAnimations });
                    //TweenMax.
                    docBody.mousedown(onDocBodyRelease);
                }
            }
            else {
                $(this).trigger("funcClick"); // navigateToTabLink(tabsA.index(this));  // need to factor this out
            }
            return false;
        });
        //*/


        function onPageScrollTabCallback(val) {
            fixedState = val;

            if (!val) {
                docBody.removeClass("tabbed");
                tabs.removeClass("expanded");
                // TweenMax.set(logoNavBgLayer, {y:0} );
            }
            else {
                docBody.addClass("tabbed");
               //  TweenMax.set(logoNavBgLayer, {y:0} );
            }
          

            var last = curLast;
            curLast = tabs[0].getBoundingClientRect().height;
            // if (val) {

            tabs.addClass("animating");
            TweenMax.from(tabs, 0.4, { height: last, ease: Cubic.easeOut, onComplete: removeAnimations });
            // }
            // else {

            // }
        }

        var unstackBreaked = 0;
        // var ALWAYS_CHECK_RESIZE = true;

        function onBreakpointDetected(mq) {
            var gotUnStack = App.BREAKPOINTS.md.matches;
            if (gotUnStack !== unstackBreaked) {
                unstackBreaked = gotUnStack;
                if (!gotUnStack) {

                    tabs.addClass("animating");
                    TweenMax.delayedCall(0.4, removeAnimatingClass);
                }
            }


            // passFixed.updateTop();
        }

        var logoNavBgLayer = $("#LogoNav .bglayer");
        function onLogoNavPass(val, interpolate) {
            //console.log(interpolate);
            if(val) {
                docBody.addClass("tabtouched");
               // if (interpolate != null) {
                 //   console.log(interpolate);
                 //   TweenMax.set(logoNavBgLayer, {y:-interpolate} );
               // }
               
            }
            else {
                docBody.removeClass("tabtouched");
                 
                // TweenMax.set(logoNavBgLayer, {y:0} );
                
            }
        }

        $(window).resize(function () {
            // if (ALWAYS_CHECK_RESIZE) {
            passFixed.updateTop();
            // }
        });

        curLast = tabs[0].getBoundingClientRect().height;
        var passFixed = new PassFixed($(tabs), onPageScrollTabCallback);
      
        tabs.data("passfixed", passFixed);
        this.passFixed = passFixed;
        App.initBreakpointListener(App.BREAKPOINTS, onBreakpointDetected);
        onBreakpointDetected();

        if ($("#LogoNav").length != 0) {
           // $("#LogoNav").css("display", "none");
            new PassFixed($(tabs), onLogoNavPass, $($(tabs).data("imposter")), -$("#LogoNav").outerHeight() );
            
        }


        //  ------  END DOCKER


        // Setup linker extensions from tabs

        var querySections = App.JQ.sectionWaypoints;
        if (querySections.length > 0) {
           App.utilities.waypointLinker =  new WaypointLinker(tabs, tabsA, querySections);
        }
        querySections = App.JQ.sectionViewPage;

        if (querySections.length > 0) {
           App.utilities.sectionTabLinker = new SectionTabLinker(tabs, tabsA, querySections);
        }
}

// Docker Tabs
(function (window, AliasBoot, $, App, undefined) {

    AliasBoot.ready(function () {

        // ---- START DOCKER

       
        //#contentWrapper > .pagescroll-tabs.pinnable-top

        var tabs = $("#PageScrollTabs");
        if (tabs.length == 0) return;
       var dockerTabs =  new DockerTabs(tabs);
      var firstChild = $($(tabs.children())[0]);
      if (firstChild.length && firstChild.data("gotsr") && !dockerTabs.passFixed.getIsPassed()) {
          //console.log("ADDING SR");
         
          firstChild.addClass("sr");
           App.sr.init(firstChild);
      }
       

    });
})(this, AliasBoot, jQuery, App);