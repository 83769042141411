// ====================================================================
// app.js
// ====================================================================

// /* jshint multistr: true */
// /* jshint validthis: true */
// /* jshint loopfunc: true */
// /* jshint boss:true  */
// /* jshint bitwise: false */
// /* jshint multistr: true */
/*jshint -W027*/
/*jshint -W041*/
/*jshint -W121*/
/*jshint -W008*/

// =====================================================================
// Core utilities
// =====================================================================

// Begin App global

/**
 * @property {WaypointLinker} waypointLinker
 * @property {SectionTabLinker} sectionTabLinker
 */
var AppUtilities = {
        waypointLinker:null,  
        sectionTabLinker:null,   
};
var App = {
    JQ: (function(){
        var obj = {
            contentWrapper:"#contentWrapper",
            mainSplash: 'main > .page-splash-section',
            mainSplashImg: null,
            hamburgerBtn: "#hamburger-zone",
            mainNav: "#MainNav",
            sectionWaypoints: "section.waypoint",   // indicates waypoint section 
            sectionViewPage: "section.viewpage",    // indicates page-based section
        };
        return obj;
    })(),
    utilities: AppUtilities,
    scrollReveal:  ScrollReveal(),
    initBreakpointListener: function (breakPts, handler) {
        if (arguments.length == 1) {
            breakPts = App.BREAKPOINTS;
            handler = arguments[0];
        }

        var i;
        var len = breakPts.length;
        for (i = 0; i < len; i++) {
            breakPts[i].addListener(handler);
        }
    }, 
    history: (function() {
        return window.history;
    })(),
    setHash: function(hashStr) {
        var history =this.history;
       
        if (history && history.replaceState) {
            history.replaceState(null, null, "#"+hashStr);
        }
        else {
            location.replace("#"+hashStr);
        }
    },
     pushHash: function(hashStr) {
        var history =this.history;
     
        if (history && history.pushState) {
            history.pushState(null, null, "#"+hashStr);
        }
        else {
            window.location.hash = "#"+hashStr;
        }
    },
    addDeeplinkListener: function(handler) {
      // if (window.onhashchange) {
    
             $(window).on("hashchange", handler);

       //}
        
    },
     removeDeeplinkListener: function(handler) {
      // if (window.onhashchange) {
    
             $(window).unbind("hashchange", handler);

       //}
        
    },
    isTouchDevice: function(){
        return typeof window.ontouchstart !== 'undefined';
    },
    isHome: function() {
        return $(document.body).children(".wrap").hasClass("page-home");
    },
    getNewBreakpoint: function(minWidth) {
        return window.matchMedia("screen and (min-width: "+minWidth+"px )");
    },
    BREAKPOINTS: (function() {
        var breakPts =  [window.matchMedia("screen and (min-width: 480px )"),
                    window.matchMedia("screen and (min-width: 768px )"),
                    window.matchMedia("screen and (min-width: 992px )"),
                    window.matchMedia("screen and (min-width: 1200px )"),
        ];
       breakPts.xs = breakPts[0];
       breakPts.sm = breakPts[1];
       breakPts.md = breakPts[2];
       breakPts.lg = breakPts[3];
       return breakPts;
    })(),
    BREAK_XS:0,
    BREAK_SM:1,
    BREAK_MD:2,
    BREAK_LG:3,
}; // single global




var AliasBoot = {
    load: function(f) {
        //$(document).ready(f);  // for dev atm
        $(window).load(f);
    },
    ready: function(f) {
        $(document).ready(f);
    }
};

// Setup initz
(function (window, document, App, AliasBoot, $, undefined) {
    'use strict';
    if (!window.matchMedia) {
        // need polyfill
        alert("Window matchmedia requires polyfill!");
    }

    // precompute jqueries
    $(document).ready( function() { 
        // expando set to jquery
        
        var jq = App.JQ;
        var p;
        for (p in jq) {
            jq[p] = $(jq[p]);
        }
        // customised searches
        jq.mainSplashImg = $(jq.mainSplash).find(".splash-img");

        // App SR
         App.sr.init();
         App.map.init();
    });

    // for any immediate functions, place here.
    // eg: 3rd-party plugins such as google maps, custom modernizr.
   // console.log('immediately load');
    

})(this, this.document, App, AliasBoot, jQuery);

// End App global


// Utiltiies below...

function SetupFunctionClicks(query) {
    query.click(function (e) {
        e.preventDefault();
        $(this).trigger("funcClick");
        return false;

    });
    return query;
}

function WaypointLinker(tabs, tabsA, myWaypoints, myWaypointsIds) {

    if (myWaypointsIds == null) myWaypointsIds = [];
    //if (myWaypoints.length == 0) return;

    tabs = $(tabs);
    tabsA = $(tabsA);

    var firstTab = $(tabsA[0]);
    function getScrollOffsetY() {

        return -firstTab.outerHeight() - 5;
    }

    var scrollTarget = $("html, body");
    var windowTarget = $(window);
    var history = window.history;

    function navigateToTabLink(indexer, duration) {
        if (duration == null) duration = 0.5;
        var me = $(tabsA[indexer]);
        tabsA.parent().removeClass("is-active");  // NOTE: this uses parent() hack, may not work in certain cases, adjust accordingly!
        me.parent().addClass("is-active");
        tabs.attr("data-index", indexer);
        

        var elem = $(myWaypoints[indexer]);
        LOCK_WAYPOINTS = true;
        var scrollTopPos = $(windowTarget).scrollTop();
        location.replace(myWaypointsIds[indexer]);

        TweenMax.set(scrollTarget, { scrollTop: scrollTopPos });
        
        TweenMax.to(scrollTarget, duration, { scrollTop: elem.offset().top + getScrollOffsetY() + (elem.data("scrolloffset") || 0), onComplete: onScrollComplete });

    }

    tabsA.bind("funcClick", function () {
        navigateToTabLink(tabsA.index(this));
    });

    function onScrollComplete() {
        LOCK_WAYPOINTS = false;
    }

  
    myWaypoints.each(function (index, item) {  // defuse waypoint ids
        item = $(item);
        myWaypointsIds.push("#" + item.attr("id"));
        item.removeAttr("id");
    });
  

    var LOCK_WAYPOINTS = false;

    this.waypoints = myWaypoints;
    this.waypointsIds = myWaypointsIds;
    this.setLock = function (val) {
        LOCK_WAYPOINTS = val;

    };
    myWaypoints.waypoint({
          offset: '25%',
        handler: function (direction) {
            if (LOCK_WAYPOINTS) return;
            var navItems = tabsA;

            var index = myWaypoints.index(this.element);
            //	console.log(index);
            navItems.parent().removeClass("is-active");
            $(navItems[index]).parent().addClass("is-active");
            tabs.attr("data-index", index);
            location.replace(myWaypointsIds[index]);
          
            if (history && history.replaceState) {
                history.replaceState(null, null, myWaypointsIds[index]);
            }
            else {
                location.replace(myWaypointsIds[index]);
            }

        }
        // , offset: '5%'
    });


     // init current history hash if any
     var startingIndex = -1;
     if ( window.location.hash ) {

       startingIndex = myWaypointsIds.indexOf(window.location.hash);
       if (startingIndex >= 0) {
        
           TweenMax.delayedCall(0, function() {  navigateToTabLink(startingIndex, 0); });
       }
     }
}



function SectionTabLinker(tabs, tabsA, mySections, mySectionIds, opts) {
    tabs = $(tabs);
    tabsA = $(tabsA);
    if (!opts) {
        opts = {};
    }
    mySections = $(mySections);
    var tabsTarget = $(tabs.data("imposter"));
    var tabsFixer = tabs.data("passfixed");
  

    var scrollTarget = $("html, body");
    var windowTarget = $(window);
    var history = window.history;

    if (mySectionIds === false) {
        mySectionIds = null;
    }
    else if (mySectionIds == null) mySectionIds = [];

    if (mySectionIds != null) {
        mySections.each(function (index, item) {  // defuse waypoint ids
            item = $(item);
            mySectionIds.push("#" + item.attr("id"));
            item.removeAttr("id");

        });
    }
    

    var signalPageChange = this.signalPageChange = new signals.Signal();
    var signalBeforePageChange = this.signalBeforePageChange = new signals.Signal();

    function transitionClass(elem, className, add, beforeCallback, afterCallback) {
        var i = elem.length;
        var e;
        if (add) {
            while (--i > -1) {
                e = $(elem[i]);
                if (e.hasClass(className)) {
                   
                    continue;
                }
                beforeCallback(e, add);
                e.addClass(className);
                afterCallback(e, add);
            }
        }
        else {

            while (--i > -1) {
                e = $(elem[i]);
           
                if (!e.hasClass(className)) {
                
                    continue;
                }
               
                beforeCallback(e, add);
                e.removeClass(className);
                afterCallback(e, add);
            }
           
        }
    }

    function removeStylings(tabs) {
        TweenMax.killTweensOf(tabs);
        tabs.attr("style", "");
    }
  
    function beforeClassChange(elem, add) {
        removeStylings(elem);
        //alert(elem.css("height"));
        //elem.height();
       
        //alert("HH");
        //elem[0].getBoundingClientRect();
       //  elem.data("lastheight",  elem[0].getBoundingClientRect().top );
    }

    function afterClassChange(elem, add) {
        // TweenMax.from(elem, 0.5, { height: elem.data("lastheight"), onComplete:removeStylings, onCompleteParams:[elem] });
      //  if (!add) {
           // TweenMax.from(elem, 0.5, { height: 0, onComplete: removeStylings, onCompleteParams: [elem] });
     //   }
    }
   

    var _lastIndexer = 0;
    this.getLastIndex = function() {

        return _lastIndexer;
    };
    
    function navigateToTabLink(indexer) {
      

        if (indexer == null) {
            alert("EXCEPTION:: Could not resolve indexer");
            return;
        }
        //
        var me = $(tabsA[indexer]);   // bleh repeated code as above
        
        tabsA.parent().removeClass("is-active");  
        me.parent().addClass("is-active");
        tabs.attr("data-index", indexer);

        // difference lies below...


        //mySections.addClass("hidden");

        me = $(mySections[indexer]);

        if (_lastIndexer != indexer) signalBeforePageChange.dispatch(indexer);

        if (mySectionIds != null)
          TweenMax.to(scrollTarget, 0.5, { scrollTop: (tabsFixer ? !tabsFixer.getIsPassed() ? tabs.offset().top : tabsTarget.offset().top : tabs.offset().top) + 0 });


          
          

        transitionClass( mySections.filter(function (index) { return index != indexer; } ) , "hidden", true, beforeClassChange, afterClassChange);
        
         transitionClass(me, "hidden", false, beforeClassChange, afterClassChange);


       
         if (mySectionIds != null ) {
                if (history && history.replaceState) {
                    history.replaceState(null, null, mySectionIds[indexer]);
                }
                else {
                    location.replace(mySectionIds[indexer]);
                }
         }


         if (_lastIndexer != indexer) signalPageChange.dispatch(indexer);
       _lastIndexer = indexer;
     
        
    }

    tabsA.bind("funcClick", function () {
        navigateToTabLink(tabsA.index(this));
    });

    // init current history hash if any
      var startingIndex = -1;
    if (mySectionIds != null &&  window.location.hash ) {
          var theHash = window.location.hash;
          theHash = theHash.split("/").pop();
           startingIndex = mySectionIds.indexOf(theHash);
            if (startingIndex >= 0) {
              TweenMax.delayedCall(0, function() {  navigateToTabLink(startingIndex, 0); });
        }
    }
}


function VWSlideshow() {
    // values to set/inject as you wish

    function getEdgeVisibility() {  // Warning... app-specific!
       return App.BREAKPOINTS[App.BREAK_LG].matches ?  17.6666 : App.BREAKPOINTS[App.BREAK_MD].matches ? 17.666 :  0;
       // return App.BREAKPOINTS[App.BREAK_LG].matches ? 17.6666 : App.BREAKPOINTS[App.BREAK_SM].matches ? 2.6666 :  2.6666;
    }
    
   var edgeVisibility=  getEdgeVisibility();
   var leftOffset= (1/3/2*100);
   var slideOffsetMultiple;
   var slideOffseter;

   function validateSlideOffsets(initOnly) {
     slideOffsetMultiple=  100-leftOffset - edgeVisibility;
     slideOffseter = 100 - 100-leftOffset - edgeVisibility;
     
     if (initOnly ) return;

   }


   function onBreakpointChange() {
       edgeVisibility =   getEdgeVisibility();
       validateSlideOffsets();
     
       slides.each( function(index, item ) {
           item = $(item);
        if (index != 0) {
                item.css("left", (index*100)-(leftOffset * index) + 'vw' );
                item.css("margin-left", (-edgeVisibility * index) + "vw" );
            }
        });
       slideBackToCurSlideNow();
   }
  // App.initBreakpointListener(App.BREAKPOINTS, onBreakpointChange);
   
   validateSlideOffsets(true);
 
   var slidesContainer = $("#SlidesContainer");
   var onSlideIndexChange = this.onSlideIndexChange = new signals.Signal();
   var onSlidingComplete = this.onSlidingComplete = new signals.Signal();


   var slides  = slidesContainer.children();


   if (slidesContainer.data("slide-click-enabled")) {

       slides.hammer().bind("tap", function() {
            if (panStarted) return;
          var targIndex = $(this).data("slideindex");
          if (targIndex != curSlideIndex) {
              slideTo(targIndex);
          }
       });
   }

//
  var prevSlide =  this.prevSlide = function() {
       var targIndex = curSlideIndex - 1;
       if (targIndex >= 0) {
          slideTo(targIndex);
          return true;
       }
       else {
           return false;
       }
   };
   
   var nextSlide = this.nextSlide = function() {
        var targIndex = curSlideIndex  + 1;
        if (targIndex < slides.length) {
           // console.log("going to targIndex:"+targIndex);
            slideTo(targIndex);
            return true;
        }
        else {
            return false;
        }
   };

   var slideBackToCurSlide =  this.slideBackToCurSlide = function() {
        var index = curSlideIndex;
     
            _isAutoSliding = true;

         TweenMax.to(_me_, .5, { set_vwoffset:(-index*slideOffsetMultiple ),  ease:Cubic.easeInOut, onComplete:slideComplete });


        
   };

    var slideBackToCurSlideNow =  this.slideBackToCurSlideNow = function() {
        var index = curSlideIndex;
     
        
        _me_.set_vwoffset(-index*slideOffsetMultiple  );
       

        
   };


   this.getTotalSlides = function() {
       return slides.length;
   };
    this.getSlidesJquery = function() {
       return slides;
   };


   this._vwoffset = 0;
   this.set_vwoffset = function(val) {
       this._vwoffset = val;
       slidesContainer.css("transform", "translateX("+val+"vw)");
   };
   this.get_vwoffset = function() {
       return this._vwoffset;
   };
  
   
   slides.each( function(index, item) {
   
       item = $(item);
       item.data("slideindex", index);

       if (index ==0) {
            item.addClass("ready");
           return;
       }
       //item.css("transform", "translateX("+(index*100)+"vw)");
       item.css("left", (index*100)-(leftOffset * index) + 'vw' );
       item.css("margin-left", (-edgeVisibility * index) + "vw" );
    
        item.addClass("ready");
   });




   slidesContainer.addClass("ready");


   var _isAutoSliding = false;
   function slideComplete() {
       _isAutoSliding = false;
       slidesContainer.removeClass("animating");

       onSlidingComplete.dispatch(curSlideIndex);
      hammSucceedMode = null;
   }

   var curSlideIndex = 0;
   this.getSlideIndex = function() {
       return curSlideIndex;
   };
   
   var _me_ = this;
     var slideTo = this.slideTo = function(index) {
      //   console.log(index+1);
         slidesContainer.addClass("animating");
         _isAutoSliding = true;
         slides.removeClass("active");
         $(slides[index]).addClass("active");
         TweenMax.to(_me_, .75, { set_vwoffset:(-index*slideOffsetMultiple ),  ease:Cubic.easeInOut, onComplete:slideComplete });
        
        
         var gotChanged = curSlideIndex != index;
         curSlideIndex = index;
          //console.log("Setting curslideIndex:"+curSlideIndex);
         if (gotChanged) onSlideIndexChange.dispatch(curSlideIndex);
         
     //   slidesContainer.css("transform", "translateX("+(index != 0 ? -index*slideOffsetMultiple : 0)+"vw)");
    };

    var slideToInstant = this.slideToInstant = function(index, slideCompleteDelay) {
        slidesContainer.removeClass("animating");
        TweenMax.killTweensOf(_me_);
          slides.removeClass("active");
         $(slides[index]).addClass("active");
          TweenMax.set(_me_, { set_vwoffset:(-index*slideOffsetMultiple ),  ease:Cubic.easeInOut });
         
        var gotChanged = curSlideIndex != index;
         curSlideIndex = index;
         if (gotChanged) onSlideIndexChange.dispatch(curSlideIndex);

          if (slideCompleteDelay ) {
               slidesContainer.addClass("animating");
               _isAutoSliding = true;
              TweenMax.delayedCall(slideCompleteDelay, slideComplete);
          }
           else slideComplete();
    };


        var slideCap = slides.length - 1;
         var sliderEl = slidesContainer.parent()[0];
         $(sliderEl).mousedown( function(e) {
             e.preventDefault();
         });
        var slideCount = 3;
        var panStarted = false;

        var activeSlide = 0; // NEW: the current slide # (0 = first)
        var sliderManager = new Hammer.Manager(sliderEl);
        var hammerPan = new Hammer.Pan({ threshold: 5, pointers: 0,  direction:Hammer.DIRECTION_HORIZONTAL });
        var hammerSwipe =  new Hammer.Swipe({ threshold:25, velocity:0.6, direction:Hammer.DIRECTION_HORIZONTAL });
         hammerSwipe.recognizeWith(hammerPan);
        var hammSucceedMode = null;     // null - neutral gear,  false - touch-swiping gear,   true - panning-swipe gear
        sliderManager.add( hammerPan );
         sliderManager.add(hammerSwipe );

           var curpanvw;
          


        var theAmountOffset;
        sliderManager.on('panend', function(e) {
            
    
    
            if (!_isAutoSliding && hammSucceedMode!==false  ) {

               
                  //  hammSucceedMode = true;
                   //
                    TweenMax.delayedCall(0, function() {
                         if (hammSucceedMode === false) return;
                      
                         if (theAmountOffset < -40) {
                             if ( !nextSlide() ) {
                                slideBackToCurSlide();
                             }
                         }
                         else if (theAmountOffset > 40) {
                            if ( !prevSlide() ) {
                                 slideBackToCurSlide();
                            }
                        }
                         else  {
                             slideBackToCurSlide();
                           //  console.log("BACK");    
                         } 
                        hammSucceedMode = true;
                        
                       
                    });
                
                
            }
           
            panStarted = false;
        });

        sliderManager.on('pan', function(e) {
            if (_isAutoSliding) return true;
            var baseNum = window.innerWidth/2;
        var percentage = baseNum / slideCount * e.deltaX / window.innerWidth;
        var transformPercentage = percentage - baseNum / slideCount * activeSlide; // NEW
        var hPerc = e.deltaX / window.innerWidth;
        hPerc*=.5;

        


        //console.log(e.deltaX + "/ "+window.innerWidth, hPerc, tarTime);
      //  sliderEl.style.transform = 'translateX( ' + transformPercentage + '% )';
       theAmountOffset =  hPerc*slideOffsetMultiple*1.5;
      var tarvwoffset = (curpanvw +  theAmountOffset);
     

     // if (tarvwoffset + curpanvw < 0 ) tarvwoffset = -curpanvw;
    //  if (tarvwoffset + curpanvw > slideCap * slideOffsetMultiple) tarvwoffset = slideCap * slideOffsetMultiple - curpanvw; 
      _me_.set_vwoffset(tarvwoffset);
    
    /*
      if (tarTime < 0) {
          tarTime = 0;
  
      } 
      if (tarTime > 1) {
          tarTime = 1;

      }
      */ 
    //  lerpSlideTween.time(tarTime);
   
        });


           sliderManager.on('swipeleft', function(e) {
               
                if (hammSucceedMode === true) return;
                   //   console.log("SWIPE detected:"+curSlideIndex);
               if (  nextSlide() )  hammSucceedMode = false;
                //  else console.log("FAILED");
                
           });
              sliderManager.on('swiperight', function(e) {
                 
                 if (hammSucceedMode === true) return;
              //    console.log("SWIPE detected2:"+curSlideIndex);
               if ( prevSlide() ) hammSucceedMode = false;
              // else console.log("FAILED");
           });

        sliderManager.on('panstart', function(e) {
              curpanvw  = _me_.get_vwoffset();
            panStarted = true;
        });

}


function PassFixed(element, callback, imposter, myScrollOffset) {
    element = $(element);
    if (!myScrollOffset )  myScrollOffset = 0;
    if (!element.length) {
        this.handler = function () {
            // do nothing
        };
        this.updateTop = function () {

        };
        return;
    }
    var _window = $(window);
  
    imposter = imposter != null ? imposter :  element.data("imposter") ? $(element.data("imposter")) : null;
      var scrollPt = (element).offset().top + myScrollOffset;

    if (imposter != null) {
        this.updateTop = function () {
            var prevFix = false;
            if (element.hasClass("fixed")) {
              
                prevFix = true;
            }
            scrollPt = (prevFix ? imposter : element ).offset().top  + myScrollOffset;
          //  console.log("UPDATE:" + scrollPt);
          
            handler();

        };
    }
    else {  // NOTE: this implementation doesn't yield accruate results..
        this.updateTop = function () {
            var prevFix = false;
            if (element.hasClass("fixed")) {
                element.removeClass('fixed');
                prevFix = true;
            }
            scrollPt = element.offset().top + myScrollOffset;
         //   console.log("UPDATE:"+scrollPt);
            if (prevFix) {
                element.addClass("fixed");
            }
            handler();
     
        };
    }

    var flagged = 0;
    this.getIsPassed = function () {
        return flagged;

    };

    function handler() {

        var scrollTop = _window.scrollTop();
        var result = scrollTop >= scrollPt;
        if (flagged !== result) {
            flagged = result;
            if (result) {
               if (myScrollOffset === 0) element.addClass("fixed");
                if (imposter != null && myScrollOffset === 0) imposter.addClass("fixed");
                if (callback != null) callback(true);
            }
            else {
                if (myScrollOffset === 0) element.removeClass("fixed");
                if (imposter != null && myScrollOffset === 0) imposter.removeClass("fixed");
                if (callback != null) callback(false);
            }

        }

       // console.log(myScrollOffset + ", "+ result + ", "+(scrollTop - scrollPt) + ", "+myScrollOffset );
        var diff;
        if (myScrollOffset !== 0 && result && (diff = scrollTop - scrollPt) < -myScrollOffset )  {

             callback(true, diff-1);
        }

     

    }

    
    this.handler = handler;
    _window.scroll(handler);

    handler();
}






// Core setup below
// =====================================================================


// Core page loaded
(function (window, AliasBoot, $, App, undefined) {
    AliasBoot.load(function () {

        $(document.body).addClass("page-loaded");
    });
})(this, AliasBoot, jQuery, App);




// Height Wrapper
/*
(function (window, AliasBoot, $, App, undefined) {
    var tarContentHeight;
    var contentWrapper = App.JQ.contentWrapper;
    if (contentWrapper.length === 0) return;


    AliasBoot.ready(function () {
        // TweenMax.to();
        contentWrapper.height();
        tarContentHeight = contentWrapper[0].getBoundingClientRect().height;
       contentWrapper.height(0);
    });

    AliasBoot.load(function () {
        TweenMax.to(contentWrapper, .6, { ease:Quad.easeOut, height: tarContentHeight });
    });

})(this, AliasBoot, jQuery, App, undefined);
*/







