// Hamburger Btn
(function (window, AliasBoot, $, App, undefined) {

    AliasBoot.ready(function () {

         var scrollTarget = $("html, body");
         var windowTarget = $(window);

          if (!App.isHome()) {
            return;
          }

        var thresholdValue = 400;
        var thresholdValue2;

        var passMark = -1;
        var hamburg =$("#HomeWaypointer");
        var splashSection = $("main > .home-splash-section");
        var contactMap = $("#contactmap");

        var navBreakpoint = App.BREAKPOINTS[App.BREAK_MD];
        var isMatchBreakpoint = false;

        var hamburgz = hamburg;
        var hamburgzOffset; //hamburgz.offset().top + hamburgz.innerHeight() - windowTarget.scrollTop() -5;

        function checkScrollPosition() {
            if (!isMatchBreakpoint) return;
            var st = windowTarget.scrollTop() + hamburgzOffset;
            var gotPassMark = st  > thresholdValue && st < thresholdValue2;
            if (gotPassMark != passMark) {
                passMark = gotPassMark;
                if (gotPassMark) {
                    hamburgz.addClass("overwhite");
                }
                else {
                    hamburgz.removeClass("overwhite");
                }
            }
        }

        function onResize() {
            thresholdValue = splashSection.height();
            thresholdValue2 = contactMap.offset().top + 100;
            hamburgzOffset = hamburgz.offset().top + hamburgz.innerHeight() - windowTarget.scrollTop();
        }


        function breakpointHandler() {

             isMatchBreakpoint =  navBreakpoint.matches;
            checkScrollPosition();
        }

        onResize();

        App.initBreakpointListener(App.BREAKPOINTS, breakpointHandler);
        breakpointHandler();

        windowTarget.scroll( checkScrollPosition);

        windowTarget.resize( onResize);



        // set up waypointer

      
        new WaypointLinker( hamburg, SetupFunctionClicks(hamburg.children("li").children("a")), $("section.waypointz")   );

        
    
    });
})(this, AliasBoot, jQuery, App);