// /* jshint multistr: true */
// /* jshint validthis: true */
// /* jshint loopfunc: true */
// /* jshint boss:true  */
// /* jshint bitwise: false */
// /* jshint multistr: true */
/*jshint -W027*/
/*jshint -W041*/
/*jshint -W121*/
/*jshint -W008*/


// TypedJS impl
(function (window, AliasBoot, jQuery, App, undefined) {
    'use strict';
    var _initOptions;
    var _initTyped;


    function getDefaultStrings(item, itemLines) {
        return itemLines.length > 0 ? getItemLines(itemLines) : [item.html().split(",").join(",^700").split("<br>").join("\n")];
    }

    function getItemLines(itemLines) {
        var len = itemLines.length;
        var i;
        var lines = [];
        for (i = 0; i < len; i++) {
            lines.push($(itemLines[i]).text());
        }
        return lines;
    }

    function getDurationOfString(str, typeSpeed) {
        var rg = /[\^][0-9]+/g;
        var splitted = str.split(rg);

        if (splitted.length > 1) {
            var i = splitted.length;
            var dur = 0;
            while (--i > -1) {
                dur += splitted[i].length * typeSpeed;
            }
            splitted = str.match(rg);
            while (--i > -1) {
                dur += parseInt(splitted[i].slice(1));
            }

            return dur;
        }
        else return str.length * typeSpeed;
    }

    function getDurationOfOptions(options) {
        var duration = 0;
        var strings = options.strings;
        duration += options.startDelay ? options.startDelay : 0;
        var i = strings.length;
        while (--i > -1) {
            duration += getDurationOfString(strings[i], options.typeSpeed);
        }
        return duration;
    }

    function moduleAvailable() {
        var me = App.JQ.mainSplash;

        if (me.data("disableanim") || me.length == 0) return false;
        return true;
    }

    AliasBoot.ready(function () {
        //main > .home-splash-section h1, 

        var me = App.JQ.mainSplash;

        if (!moduleAvailable()) return;
        me = me.find("h1");

        var innerTyped = $('<span class="typing"></span>');

        me.wrapInner(innerTyped);
        // return;
        innerTyped = me.children();
        // innerTyped.css("white-space", "pre");
        var meLines = innerTyped.children("span.line");
        var strings = me.data("customtype") || getDefaultStrings(innerTyped, meLines);

        _initOptions = {
            //string
            strings: strings,
            startDelay: 0,
            typeSpeed:5,
            // call when done callback function
            callback: function () { },
            // starting callback function before each string
            preStringTyped: function () { },
            //callback for every typed string
            onStringTyped: function () { },
            // callback for reset
            resetCallback: function () { }
        };

        // alert(me.css("line-height"));
        me.css("min-height", me.css("line-height"));
        innerTyped.html("");

        _initTyped = innerTyped;


    });


    AliasBoot.ready(function () {
        if (!moduleAvailable()) return;

        var coreDelay = 1.2;
        var totalDurationOfTyping = getDurationOfOptions(_initOptions) / 1000;
        //
        TweenMax.from(App.JQ.mainSplashImg, coreDelay + totalDurationOfTyping, { scaleX: 1.04, scaleY: 1.04 });
        // TweenMax.from();
        TweenMax.delayedCall(coreDelay, function () {

            _initTyped.typed(_initOptions);
        });
    });

})(this, AliasBoot, jQuery, App);
