 App.map = (function() {

var MAP_ID = "contactmap";
var baseurl = '';
var styledMap;
var mapDiv;
var map;
var imageMarker;
var HTML = $('html');
var psMarker;


var locations = [
    {
        // singapore
        lat: 1.302459,
        lng: 103.8337868,
        latMarker : 1.3025946,
        lngMarker : 103.8344987,
        name: 'default',
        bgClass : 'bg-default',
        mapurl : 'https://maps.google.com/maps?f=q&source=embed&hl=en&geocode=&q=391+Orchard+Road+Singapore&aq=0&oq=391+orchard+road&sll=37.0625,-95.677068&sspn=46.677964,107.138672&ie=UTF8&hq=&hnear=391+Orchard+Rd,+Singapore+238872&t=m&ll=1.302798,103.834748&spn=0.012871,0.012875&z=15&iwloc=A'
    }
];

 var styles = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "lightness": 40
            },
            {
                "visibility": "on"
            },
            {
                "color": "#f4f4fe"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#0f252e"
            },
            {
                "lightness": 17
            }
        ]
    }
];

  return {
        init : function() {


            if ( !$("#"+MAP_ID).length) {
                return;
            }

            mapDiv = $("#"+MAP_ID);

          //  var gotZoomControl = mapDiv.data("zoomControl")!= null ? mapDiv.data("zoomControl") : true;

            AliasBoot.load( function() {

                  if (typeof google === 'object' && typeof google.maps === 'object') {



                    styledMap = new google.maps.StyledMapType(styles, {name: 'YesPay Map'});

                  //  defaultStyledMap = new google.maps.StyledMapType(stylesDefault, {name: 'YesPay Map'});
                    map = new google.maps.Map(document.getElementById(MAP_ID), {
                        center: new google.maps.LatLng( locations[0].lat , locations[0].lng ),
                        zoom: 18,
                         scrollwheel: false,
                          draggable: true,
                          zoomControl:true,
                          scaleControl: true,
                        disableDefaultUI: true,
                         zoomControlOptions: {
                             position: google.maps.ControlPosition.RIGHT_CENTER
                          },
                        mapTypeControlOptions: {
                            mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                        }
                    });

                    map.mapTypes.set('map_style', styledMap);
                    map.setMapTypeId('map_style');

                    google.maps.event.addListener(map,'idle', function() {
                        HTML.addClass('map-loaded');
                    });

                    imageMarker = {
                        url: ''+baseurl+'images/common/map_marker.png',
                        scaledSize: new google.maps.Size(70, 70),
                        size: new google.maps.Size(100, 100),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(35, 80)
                    };
                    psMarker = new google.maps.Marker({
                        position: new google.maps.LatLng( locations[0].latMarker , locations[0].lngMarker ),
                        icon: imageMarker,
                        map: map,
                        url: locations[0].mapurl
                    });

                    map.setCenter( new google.maps.LatLng( locations[0].lat, locations[0].lng ) );




                } else {

                    // console.log('nope');
                    HTML.addClass('map-loaded no-googlemaps');
                    mapDiv.addClass('fa'+locations[0].bgClass+''); // set sg as default

                }

            });
        }
    };

 })();