App.lightbox = {

    // settings
    lb : $('.lb'),
    lbBtnOpen : $('.lb-btn-open'),
    lbBtnClose : $('.lb-btn-close'),

    deactivate : function() {
        console.log('close');
    },

    activate : function() {
        console.log('open');
    },

    bindEvents : function() {
        this.lbBtnOpen.on('click', App.lightbox.activate );
        this.lbBtnClose.on('click', App.lightbox.deactivate );
    },

    init : function() {
        this.bindEvents();
        console.log('lightbox init');

    }

};