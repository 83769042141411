// /* jshint multistr: true */
// /* jshint validthis: true */
// /* jshint loopfunc: true */
// /* jshint boss:true  */
// /* jshint bitwise: false */
// /* jshint multistr: true */
/*jshint -W027*/
/*jshint -W041*/
/*jshint -W121*/
/*jshint -W008*/

// Hamburger Btn
(function (window, AliasBoot, $, App, undefined) {
    var menuOpen = false;
   

    AliasBoot.ready(function () {

        var hamburgz = App.JQ.hamburgerBtn.add(App.JQ.hamburgerBtn.find(".hamburger")).add(App.JQ.mainNav);

        App.JQ.hamburgerBtn.click(function () {
            menuOpen = !menuOpen;

            if (menuOpen) {
                hamburgz.addClass("active");

            }
            else {
                hamburgz.removeClass("active");
            }
        });

    });
})(this, AliasBoot, jQuery, App);


// Hamburger Btn
(function (window, AliasBoot, $, App, undefined) {
 
    var scrollTarget = $("html, body");
    var windowTarget = $(window);
    AliasBoot.ready(function () {
        var thresholdValue = 400;

        var passMark = -1;
        var hamburg = $(App.JQ.hamburgerBtn);
       

        var isHome = App.isHome();

        if (App.isHome()) {
             var homeSplashSection = $("main > section.home-splash-section");
        }
        var hamburgz = $(App.JQ.hamburgerBtn).find(".hamburger");
         var hamburgC = isHome || App.JQ.sectionWaypoints.length === 0 ?  hamburgz.add("#LogoNav") : hamburgz;
        var hamburgzOffset = hamburgz.offset().top + hamburgz.innerHeight() - windowTarget.scrollTop() -5;

        function checkScrollPosition() {
            var gotPassMark =  windowTarget.scrollTop() + hamburgzOffset > thresholdValue;
            if (gotPassMark != passMark) {
                passMark = gotPassMark;
                if (gotPassMark) {
                    hamburgC.addClass("overwhite");
                }
                else {
                    hamburgC.removeClass("overwhite");
                }
            }
        }

        

        


        function breakpointHandler() {

            if (!isHome) {
                if (App.BREAKPOINTS[App.BREAK_MD].matches ) {
                        thresholdValue = 400;
                }
                else {
                    thresholdValue = 70 + 550;
                }
            }
            else {
                thresholdValue = homeSplashSection.height();
            }
           
            checkScrollPosition();
        }

        App.initBreakpointListener(App.BREAKPOINTS, breakpointHandler);
        breakpointHandler();

        windowTarget.scroll( checkScrollPosition);
        
        /*
        windowTarget.resize(function () {
            checkScrollPosition();
        });
        */
    });
})(this, AliasBoot, jQuery, App);