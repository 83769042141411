// /* jshint multistr: true */
// /* jshint validthis: true */
// /* jshint loopfunc: true */
// /* jshint boss:true  */
// /* jshint bitwise: false */
// /* jshint multistr: true */
/*jshint -W027*/
/*jshint -W041*/
/*jshint -W121*/
/*jshint -W008*/


function VideoSlideshow(videoTagsJ) {

    /**
     * @param {jQuery}  fromJQuery
     * @return {HTMLVideoElement[]}    Array of videos
     */
    function getVideoList(fromJQuery) {
        var arrayOfVideos = [];
   
        fromJQuery.each( function(index, item) {
           arrayOfVideos.push(item);
            TweenMax.set(item,  { alpha:0} );
          //  alert(item);
        });
        return arrayOfVideos;
    }

    /**
     * @param {HTMLVideoElement} v
     */
    function handleStopVideo(v) {
         v.pause();
           $(v).css("visibility", "hidden");
         v.currentTime = 0;
    }

   var videoList = getVideoList(videoTagsJ);
   var curIndex = -1;
   var crossFadeDuration = .5;
   var signalOnProgress = new signals.Signal();
   this.signalOnProgress = signalOnProgress;

   var _isActive = true;
   var _activeId;

    /*
   function onEnterFrame2() {
        if (curIndex < 0) return;

         var v =videoList[0];
        if (isNaN(v.duration)  || v.duration == 0) {
          window.requestAnimationFrame(onEnterFrame);
          return;
      } 
   }
   */

   function onEnterFrame() {
       if (curIndex < 0) return;

       var v =videoList[curIndex];

      if (isNaN(v.duration)  || v.duration == 0) {
          window.requestAnimationFrame(onEnterFrame);
          return;
      } 
       var cap =  (v.duration-crossFadeDuration);

       var prog = v.currentTime / cap;
  
     
       
       if (prog >= 1 ) {
           prog = 0;
           playNextVideo();
       }
       else signalOnProgress.dispatch( prog );
    
       if (!_isActive) return;

       window.requestAnimationFrame(onEnterFrame);
   }

  _activeId =  window.requestAnimationFrame( onEnterFrame);


   this.resumeSlideshow = function() {
       if (_isActive) return;
       _isActive = true;
        if ( curIndex>=0 && videoList[curIndex] ) {
         // if (videoList[curIndex].paused) videoList[curIndex].play();
       }
        _activeId =window.requestAnimationFrame(onEnterFrame);
   };

   this.stopSlideshow = function() {
       if (!_isActive) return;
       _isActive = false;
       if ( curIndex>=0 && videoList[curIndex] ) {
         
       }
        window.cancelAnimationFrame(_activeId);
   };


    function playNextVideo() {
        var v = videoList[0];
        if (curIndex >=0) {
            v = videoList[curIndex];
           TweenMax.to( v, crossFadeDuration, {alpha:0, ease:Cubic.easeOut, onComplete:handleStopVideo, onCompleteParams:[v]});
        }
        curIndex++;
        if (curIndex >= videoList.length) {
            curIndex = 0;
        }
        v =  videoList[curIndex];
       
       signalOnProgress.dispatch(0);
       
       $(v).css("visibility", "visible");
      
         v.play();
         
        TweenMax.to( v, crossFadeDuration, {alpha:1, ease:Cubic.easeOut});
      // TweenMax.delayedCall( v.duration- crossFadeDuration, playNextVideo );
    
    }

    
    

    playNextVideo();


}