// /* jshint multistr: true */
// /* jshint validthis: true */
// /* jshint loopfunc: true */
// /* jshint boss:true  */
// /* jshint bitwise: false */
// /* jshint multistr: true */
/*jshint -W027*/
/*jshint -W041*/
/*jshint -W121*/
/*jshint -W008*/
App.sr = (function() {
	'use strict';
    var sr =App.scrollReveal;

    function srStaggerAnimate( options ) {

        var el = options.el;
        var origin = options.origin;
        var dist = options.dist;
        var duration = options.duration;
        var delay = options.delay;
        var scale = options.scale;
        var view = options.view;
        var ease = options.ease;
        var stagger = parseInt( options.stagger );

        var easeDefault = 'cubic-bezier(0.6, 0.2, 0.1, 1)';
        if ( ease === 'back') {
            easeDefault = 'cubic-bezier(0, 0.45, 0.3, 1.25)';
        }

        sr.reveal( el, {
            origin      : origin || 'bottom', // 'bottom', 'left', 'top', 'right'
            distance    : dist || '8px', // Can be any valid CSS distance, e.g. '5rem', '10%', '20vw', etc.
            duration    : duration || 750, // Time in milliseconds.
            delay       : delay || 100,
            rotate      : { x: 0, y: 0, z: 0 }, // Starting angles in degrees, will transition from these values to 0.
            opacity     : 0, // Starting opacity value, before transitioning to the computed opacity.
            scale       : scale || 1, // Starting scale value, will transition from this value to 1.
            easing      : easeDefault, // Accepts any valid CSS easing, e.g. 'ease', 'ease-in-out', 'linear', etc.
            container   : 'html',
            mobile      : true, // true/false to control reveal animations on mobile.
            reset       : false,
            // 'always' — delay for all reveal animations
            // 'once'   — delay only the first time reveals occur
            // 'onload' - delay only for animations triggered by first load
            useDelay    : 'always',
            viewFactor  : view || 0.35, // 0.20 means 20% of an element must be visible for its reveal to occur.
            viewOffset  : { top: 0, right: 0, bottom: 0, left: 0 }, // Visual Aid: https://scrollrevealjs.org/assets/viewoffset.png
            afterReveal : function(domEl) {},
            afterReset  : function(domEl) {}
        }, stagger || 175 );

    }

    return {
        init : function(querier) {

            var srEl =querier != null ? querier :  $('.sr');

            var revealAfterClass = function(domEl) {
                $(domEl).addClass("sr-revealed").removeClass("sr-reset");
            };
              var hideAfterClass = function(domEl) {
                $(domEl).removeClass("sr-revealed").addClass("sr-reset").addClass("sr-resetted");
            };

            if ( srEl.length !== 0 ) {

                srEl.each(function(){

                    var el = $(this);
                    var elSr = $(this).get(0);
                     var afterReveal =   el.data('sr-css');

                    var origin = el.attr('data-sr-origin');
                    var dist = el.attr('data-sr-dist');
                    var duration = el.attr('data-sr-duration');
                    var delay = el.data('sr-delay');
                    var scale = el.attr('data-sr-scale');
                    var view = el.attr('data-sr-view');
                  var reset = el.attr('data-sr-reset');
                  var opacity = el.data('sr-opacity');
                  var useDelay = el.attr('data-sr-usedelay');

                    var easeDefault = 'cubic-bezier(0.6, 0.2, 0.1, 1)';
                    var ease = el.attr('data-sr-ease');
                    if ( ease === 'back') {
                        easeDefault = 'cubic-bezier(0, 0.45, 0.3, 1.25)';
                    }

                    sr.reveal( elSr, {
                        origin      : origin || 'bottom', // 'bottom', 'left', 'top', 'right'
                        distance    : (dist != null ? dist :  '8px'), // Can be any valid CSS distance, e.g. '5rem', '10%', '20vw', etc.
                        duration    : duration || 750, // Time in milliseconds.
                        delay       : (delay != null ? delay :  100),
                        rotate      : { x: 0, y: 0, z: 0 }, // Starting angles in degrees, will transition from these values to 0.
                        opacity     : (opacity != null ? opacity : 0), // Starting opacity value, before transitioning to the computed opacity.
                        scale       : scale || 1, // Starting scale value, will transition from this value to 1.
                        easing      : easeDefault, // Accepts any valid CSS easing, e.g. 'ease', 'ease-in-out', 'linear', etc.
                        container   : 'html',
                        mobile      : true, // true/false to control reveal animations on mobile.
                        reset       : reset || false,
                        // 'always' — delay for all reveal animations
                        // 'once'   — delay only the first time reveals occur
                        // 'onload' - delay only for animations triggered by first load
                        useDelay    : useDelay || 'always',
                        viewFactor  : view || 0.25, // 0.20 means 20% of an element must be visible for its reveal to occur.
                        viewOffset  : { top: 0, right: 0, bottom: 0, left: 0 }, // Visual Aid: https://scrollrevealjs.org/assets/viewoffset.png
                        afterReveal : (afterReveal ? revealAfterClass : null),
                       afterReset  : (afterReveal ? hideAfterClass : null),
                    });

                });

            }








        }
    };

})();



